<template>
  <div>
    <main-header />

  <section class="container py-3">
    <br /><br><br>
    <div class="d-flex flex-column align-items-center text-center">
      <Confirmation />
      <br />
      <h5>
        Votre commande a été validée
      </h5>
      <h6 class="text-normal">
        Vous allez recevoir un email
      </h6>
      <br />
      <router-link to="/" class="btn btn-action black-outline border-2 text-uppercase px-4"
        ><u>Retourner à l'accueil</u></router-link
      >
    </div>
  </section><br><br><br>

  <main-footer />
  </div>
</template>

<script>
export default {};
</script>

<style></style>
